import styled from "styled-components"

export const Wrapper = styled.div`
    padding: 0 32px;

    ${({theme}) => theme.mq.desktop} {
        padding: 0;
        width: 90%;
        margin: 0 auto;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 85%;
    }

    ${({ theme }) => theme.mq.fullhd} {
        width: 80%;
    }
` 
import React from "react"
import styled from "styled-components"
import { Span } from "../Span/Span"

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
`

const IconContainer = styled.div`
    width: 60px;
    text-align: center;

    ${({ theme }) => theme.mq.widescreen} {
        width: 45px;
    }

    ${({ theme }) => theme.mq.fullhd} {
        width: 60px;
    }
`

const StyledIcon = styled.img`
    height: 35px;

    ${({ theme }) => theme.mq.desktop} {
        height: 27px;   
    }

    ${({ theme }) => theme.mq.widescreen} {
        height: 32px;
    }

    ${({ theme}) => theme.mq.fullhd} {
        height: 35px;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`

const StyledHeading = styled.h3`
    font-size: 4.8rem;
    font-weight: normal;

    ${({theme }) => theme.mq.desktop} {
        font-size: 4rem;
    }

    ${({ theme }) => theme.mq.fullhd} {
        font-size: 4.8rem;
    }
`

const StyledSpan = styled(Span)`
    color: #000;
    letter-spacing: 1.6px;
    opacity: 0.7;
`

const NumberBoxItem = ({ className, icon, number, label }) => (
    <StyledWrapper className={className}>
        <IconContainer>
            <StyledIcon src={icon} />
        </IconContainer>
        <TextContainer>
            <StyledHeading>{number}</StyledHeading>
            <StyledSpan>{label}</StyledSpan>
        </TextContainer>
    </StyledWrapper>
)

export default NumberBoxItem
import React from "react"
import styled from "styled-components"

import NumbersBoxItem from "../../atoms/NumbersBoxItem/NumbersBoxItem"

import bedroomIcon from "../../../images/bedroom.svg"
import floorsIcon from "../../../images/floors.svg"
import carIcon from "../../../images/car.svg"
import areaIcon from "../../../images/area.svg"

const StyledWrapper = styled.div`
    margin: 64px 0;

    ${({ theme }) => theme.mq.desktop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`

const StyledNumbersBoxItem = styled(NumbersBoxItem)`
    margin-bottom: 16px;

    ${({ theme }) => theme.mq.desktop} {
        width: 100%;
        margin-bottom: 32px;
    }
`

const NumbersBox = () => (
    <StyledWrapper>
        <StyledNumbersBoxItem icon={bedroomIcon} number="118" label="łączna ilość pokoi" />
        <StyledNumbersBoxItem icon={carIcon} number="100" label="ilość miejsc parkingowych" />
        <StyledNumbersBoxItem icon={floorsIcon} number="4" label="łączna ilość pięter" />
        <StyledNumbersBoxItem icon={areaIcon} number="12 859" label="łączna powierzchnia w m2" />
    </StyledWrapper>
)

export default NumbersBox